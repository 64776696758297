<template>
    <!-- <template > -->
      <a v-if="item.new_tab || item.link" :target="item.new_tab ? '_blank' : '_self'"
       :href="item.link ? item.link : $router.resolve(findUrl(item.linkable_type, item.linkable_id, item.slug, item.link)).href">
      <slot></slot>
    </a>
    <router-link v-else :to="findUrl(item.linkable_type, item.linkable_id, item.slug)">
      <slot></slot>
    </router-link>
    <!-- </template> -->
      <!-- <option :value="item" >
  
        <slot></slot>
      </option> -->
  </template>
  
  <script>
  import findUrl from "@/libraries/links/LinkResolver";
  
  export default {
    name: "DynamiOptions",
    props: {
      item: Object,
      // flagSelect:Boolean
    },
    methods: {
      findUrl,
    //   changPage(){
    //     alert(1235)
    //     this.$router.push(this.findUrl(item.linkable_type, item.linkable_id, item.slug))
    //   }
    },
  }
  </script>
  
  <style scoped>
  a:hover {
    color: var(--color-theme3);
  }
  
  a {
    color: inherit;
  }
  </style>
  