import { render, staticRenderFns } from "./RecommendedProduct.vue?vue&type=template&id=d8ad7b88&scoped=true&"
import script from "./RecommendedProduct.vue?vue&type=script&lang=js&"
export * from "./RecommendedProduct.vue?vue&type=script&lang=js&"
import style0 from "./RecommendedProduct.vue?vue&type=style&index=0&id=d8ad7b88&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8ad7b88",
  null
  
)

export default component.exports