<template>
  <div>
    <StoryCategories />
    <slider />

    <AmazingProduct  />
    <main class="main container container-2 pt-1 p-sm-5">
      <div></div>
      <Banners
      :banners="banners[0]"></Banners>
      <RecommendedProduct></RecommendedProduct>
      <Flashes
        v-if="
          $store.getters['front/getHomeData'] != null &&
          $store.getters['front/getHomeData'].flashes != ''
        "
        :flashes="$store.getters['front/getHomeData'].flashes"
      />

      <Banners :banners="banners[1]"></Banners>
      <Weblogs></Weblogs>
    </main>
  </div>
</template>

<script>
import AmazingProduct from "../components/AmazingProduct.vue";
// import VipSection from "@/parts/Front/components/VipSection";
import slider from "@/parts/Front/components/shared/Header";
import RecommendedProduct from "@/parts/Front/components/RecommendedProduct";
import Banners from "@/parts/Front/components/Banners";
import Flashes from "@/parts/Front/components/FlashProducts";
import Weblogs from "@/parts/Front/components/Weblogs";
import SearchSection from "@/parts/Front/components/SearchSection";
// import LastInstaPosts from "@/parts/Front/components/LastInstaPosts";
import ScrollToTop from "@/parts/Front/components/ScrollToTop";
import debounce from "lodash/debounce";
import FourBanners from "@/parts/Front/components/FourBanners";
import StoryCategories from "../components/StoryCategories.vue";
// import MostDiscountProduct from "../components/MostDiscountProduct.vue";
export default {
  name: "Home",
  components: {
    FourBanners,
    // VipSection,
    slider,
    RecommendedProduct,
    Banners,
    Flashes,
    Weblogs,
    SearchSection,
    // LastInstaPosts,
    ScrollToTop,
    StoryCategories,
    // MostDiscountProduct,
    AmazingProduct,
  },
  data() {
    return {
      showInstagram: true,
      reRenderInstagramFunction: debounce(this.reRenderInstagram, 250),
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.reRenderInstagramFunction);
  },
  mounted() {
    window.removeEventListener("resize", this.reRenderInstagramFunction);
    window.addEventListener("resize", this.reRenderInstagramFunction);
  },
  methods: {
    reRenderInstagram() {
      this.showInstagram = false;
      this.$nextTick(() => {
        this.showInstagram = true;
      });
    },
  },
  computed: {
    banners() {
      if (this.$store.getters["front/getHomeData"] != null) {
        let advertise = this.$store.getters["front/getHomeData"].advertise;
        return [
          [advertise.banner_1, advertise.banner_2],
          [advertise.banner_3, advertise.banner_4],
        ];
      } else {
        return [
          [null, null],
          [null, null],
        ];
      }
    },
  },
};
</script>
