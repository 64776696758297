<template>
  <section

    v-if="amazingSuggest && amazingSuggest.length"
    id="amazingSuggest"
    class="py-4 pl-3 pr-3 pr-md-0 mt-5 w-100  d-flex justify-content-between"
  >
    <!-- <div
      id="amazingSuggestBanner"
      class="swiper-slide d-none d-md-flex flex-column align-items-center justify-content-center px-3"
    >
      <img
        width="92"
        height="77"
        src="@/assets/images/amazingSuggest.svg"
        alt="vip logo"
      />
      <div style="width: 140px; height: 115px">
        <img
          width="140"
          height="115"
          src="@/assets/images/amazingBox.png"
          alt="vip title"
        />
      </div>
      <router-link class="view-all text-light fontsize16 font-bold mt-1 border border-dark" to="/products"
        >مشاهده همه</router-link
      >
    </div> -->
    <div id="amazingSuggestproduct" class="swiper-container overflow-hidden w-100 position-relative ">
      <div class="swiper-wrapper">
        <div
          id="amazingSuggestBanner"
          class="swiper-slide d-flex d-md-none flex-column align-items-center justify-content-center px-3 swiper-slide"
          style="transform: translateY(149px);"
        >
          <img
            width="92"
            height="77"
            src="@/assets/images/amazingSuggest.svg"
            alt="vip logo"
          />
          <div style="width: 140px; height: 115px">
            <img
              width="140"
              height="115"
              src="@/assets/images/amazingBox.png"
              alt="vip title"
            />
          </div>
          <router-link
            class="text-light fontsize16 font-bold mt-1"
            to="/products?sort=most_discount"
            >مشاهده همه</router-link
          >
        </div>
        <div
        id="amazingSuggestBanner"
        class="swiper-slide d-none d-md-flex flex-column align-items-center justify-content-center px-3"
        style="transform: translateY(149px);"
      >
        <img
          width="92"
          height="77"
          src="@/assets/images/amazingSuggest.svg"
          alt="vip logo"
        />
        <div style="width: 140px; height: 115px">
          <img
            width="140"
            height="115"
            src="@/assets/images/amazingBox.png"
            alt="vip title"
          />
        </div>
        <router-link class="view-all text-light fontsize16 font-bold mt-1 border border-dark" to="/products?sort=most_discount"
          >مشاهده همه</router-link
        >
      </div>
        <div
          class="swiper-slide"
          v-for="(product, index) in amazingSuggest"
          :key="index"
        >
          <Product class="vipProduct" :product="product"></Product>
        </div>
        <div
          class="swiper-slide bg-dark more-slide d-flex justify-content-center align-items-center">
          
          <router-link class="text-light fontsize16 text-black font-bold mt-1 d-flex flex-column justify-content-center align-items-center" to="/products?sort=most_discount"
          style="gap: 8px;"
          >
          <div class="more-slide-sum-svg d-flex justify-content-center align-items-center">  
            <svg width="35px" height="35px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 6a1 1 0 1 0-2 0v5H6a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5V6Z" fill="#fff"/></svg>
        </div>
          <p>مشاهده همه</p>
          
          </router-link
        >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";

export default {
  components: {
    Product,
  },
  mounted() {
    this.initRelatedProducts();
  },
  computed: {
    amazingSuggest() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return [];
      } else {
        return this.$store.getters["front/getHomeData"].mostSales;
      }
    },
  },
  watch: {
    amazingSuggest(newValue) {
      if (newValue) {
        this.initRelatedProducts(true);
      }
    },
  },
  methods: {
    initRelatedProducts(kill = false) {
      this.initSwiper("myamazingSuggest", kill, () => {
        new Swiper("#amazingSuggestproduct.swiper-container", {
          spaceBetween: 50,
          // centeredSlides: true,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".similar-products .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            250: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            370: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            600: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
            1000: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1400: {
              slidesPerView: 5,
              spaceBetween: 40,
            },
            1600: {
              slidesPerView: 6,
              spaceBetween: 40,
            },
          },
        });
      });
    },
  },
};
</script>
<style>
section#amazingSuggest {
  /*display: flex;
  justify-content: space-between;*/
  background-color: var(--bg-color-amazing);
  

  margin-top: 13rem !important;
}
#amazingSuggest {
 height: 350px;
}
#amazingSuggest .swiper-container {
 /* overflow-x: hidden;*/
  height: fit-content;
}
div#amazingSuggestproduct {
 /* width: 100%;
  position: relative;*/
  bottom: 100px;
}
div#amazingSuggestproduct .swiper-slide {
  background: white;
  /*display: flex;
  align-items: stretch;*/
}
#amazingSuggestBanner {
  background: transparent !important;
  width: 20%;
  margin: unset !important;
}

div#amazingSuggestproduct .vipProduct img {
  border-radius: 10px;
}
#amazingSuggestproduct img:not(#amazingSuggestBanner img) {
  height: 300px;
  width: 100%;
}
.view-all{
  transition: all .3s;
  padding: 4px 8px;
}
.more-slide{
  height: initial;
 /* background-color:#323232 !important;*/
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.more-slide a{
/*  color: #000 !important;*/
}
.more-slide a:hover{
 /* color: #000 !important;*/
}
.more-slide-sum-svg{
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
}
.view-all:hover{
  background-color:#000;
  color: #fff !important;
}
@media (max-width: 1200px) {
  #amazingSuggestBanner{
    transform: translateY(88px) !important ;
  }
  #amazingSuggestproduct img:not(#amazingSuggestBanner img) {
    height: 250px !important;
  }
  #amazingSuggest {
    height: 300px !important;
  }
  #amazingSuggestproduct{
    bottom: 82px !important;
  }
}
@media (max-width: 768px) {
  section#amazingSuggest {
    border-radius: 2px;
    margin-top: 11rem !important;
    
  }
  #amazingSuggestproduct img:not(#amazingSuggestBanner img) {
    height: 230px !important;
  }
  #amazingSuggest {
    height: 260px !important;
  }

  /*#amazingSuggestBanner {
    display: none !important;
  }*/
  #amazingSuggestproduct{
    bottom: 100px !important;
  }
}
@media (max-width: 600px) {
  section#amazingSuggest {
    margin-top: 8rem !important;
  }
  #amazingSuggestproduct img:not(#amazingSuggestBanner img) {
    height: 200px !important;
  }
  #amazingSuggest {
    height: 230px !important;
  }
}
@media (max-width: 500px) {
  #amazingSuggestproduct img:not(#amazingSuggestBanner img) {
    height: 200px !important;
  }
  #amazingSuggest {
    height: 230px !important;
  }
}
/*@media (max-width: 400px) {
  #amazingSuggestproduct img {
    height: 200px !important;
  }
  #amazingSuggest {
    height: 230px !important;
  }
}*/
</style>
