<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-transparent navbarHeader">
    <!-- Container wrapper -->
    <div class="container-fluid h-100">
      <!-- Collapsible wrapper -->
      <div class="collapse navbar-collapse" id="navbarExampleOnHover">
        <b-row id="menuSkelton" v-if="status">
          <b-skeleton height="25px" width="65px" class="mr-3" type="input" />
          <b-skeleton height="25px" width="65px" class="mr-3" type="input" />
          <b-skeleton height="25px" width="65px" class="mr-3" type="input" />
          <b-skeleton height="25px" width="65px" class="mr-3" type="input" />
        </b-row>
        <!-- Left links -->
        <ul
          v-else
          class="navbar-nav me-auto ps-lg-0"
          style="padding-left: 0.15rem; gap: 16px"
        >
          <!-- Navbar dropdown -->
          <li
            v-for="item in menu"
            :key="item.id"
            :class="[
              item.children.length != 0
                ? ['has-children', 'dropdown-hover']
                : '',
            ]"
            class="d-flex align-items-center justify-content-center nav-item dropdown bg-transparent position-static"
            style="min-height: 76.5px"
          >
            <a 
              v-if="item.new_tab || item.link"
              :target="item.new_tab ? '_blank' : '_self'"
              :href="
                item.link
                  ? item.link
                  : $router.resolve(
                      findUrl(
                        item.linkable_type,
                        item.linkable_id,
                        item.slug,
                        item.link
                      )
                    ).href
              "
              class="nav-link dropdown-toggle text-dark weight-bold"
              id="navbarDropdown"
              role="button"
              data-mdb-toggle="dropdown"
              aria-expanded="false"
            >
              {{ item.title }}
              <span v-if="item.children.length != 0">
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 8.5L12 15.5L5 8.5"
                    stroke="#444"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </a>
            <router-link
              v-else
              class="nav-link d-inline-block weight-bold  text-dark"
              :to="findUrl(item.linkable_type, item.linkable_id, item.slug)"
            >
              {{ item.title }}
              <span v-if="item.children.length != 0">
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 8.5L12 15.5L5 8.5"
                    stroke="#444"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </router-link>
            <!-- Dropdown menu -->
            <div
              class="dropdown-menu w-100"
              aria-labelledby="navbarDropdown"
  style="border-top-left-radius: 0; border-top-right-radius: 0; border-bottom: 2px solid #000; border-top:1px solid #dbdbdb"
            >
              <div class="container">
                <div class="row mb-4" style="flex: 1">
                  <ul
                    v-if="item.children.length != 0"
                    class="d-flex list-unstyled justify-content-center align-items-start bg-white"
                    style="gap: 7rem"
                  >
                    <li
                      v-for="(childMenu,index) in item.children"
                      :class="{
                        'has-child-children': childMenu.children.length != 0,
                      }"
                      :key="childMenu.id"
                      class="nav-item"
                    >
                      <a 
                        :target="childMenu.new_tab ? '_blank' : '_self'"
                        class="nav-link fontsize18 text-dark font-weight-bolder"
                        v-if="childMenu.new_tab || childMenu.link"
                        :href="
                          childMenu.link
                            ? childMenu.link
                            : $router.resolve(
                                findUrl(
                                  childMenu.linkable_type,
                                  childMenu.linkable_id,
                                  childMenu.slug,
                                  childMenu.link
                                )
                              ).href
                        "
                        >{{ childMenu.title }}
                        <!-- <span v-if="childMenu.children.length != 0">
                          <svg
                            style="position: relative; top: -2px"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-chevron-left"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                            />
                          </svg>
                        </span> -->
                      </a>
                      <router-link 
                        v-else
                        class="nav-link fontsize18 text-dark font-weight-bolder"
                        :to="
                          findUrl(
                            childMenu.linkable_type,
                            childMenu.linkable_id,
                            childMenu.slug
                          )
                        "
                        >{{ childMenu.title }}
                        <!-- <span v-if="childMenu.children.length != 0">
                          <svg
                            style="position: relative; top: -2px"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-chevron-left"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                            />
                          </svg>
                        </span> -->
                      </router-link>
                      <ul
                        v-if="childMenu.children.length != 0"
                        class="list-unstyled justify-content-center align-items-center bg-white"
                      >
                        <li
                          :class="{
                            'has-child-children':
                              childrenMenu.children.length != 0,
                          }"
                          v-for="childrenMenu in childMenu.children"
                          :key="childrenMenu.id"
                          class="nav-item"
                        >
                          <a
                            v-if="childrenMenu.new_tab || childrenMenu.link"
                            class="nav-link childMenu"
                            :target="childMenu.new_tab ? '_blank' : '_self'"
                            :href="
                              childrenMenu.link
                                ? childrenMenu.link
                                : $router.resolve(
                                    findUrl(
                                      childrenMenu.linkable_type,
                                      childrenMenu.linkable_id,
                                      childrenMenu.slug,
                                      childrenMenu.link
                                    )
                                  ).href
                            "
                            >{{ childrenMenu.title }}
                            <!-- <span v-if="childrenMenu.children.length != 0">
                              <svg
                                style="position: relative; top: -2px"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-left"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                />
                              </svg>
                            </span> -->
                          </a>
                          <router-link
                            v-else
                            class="nav-link childMenu"
                            :to="
                              findUrl(
                                childrenMenu.linkable_type,
                                childrenMenu.linkable_id,
                                childrenMenu.slug
                              )
                            "
                            >{{ childrenMenu.title }}
                            <!-- <span v-if="childrenMenu.children.length != 0">
                              <svg
                                style="position: relative; top: -2px"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-left"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                />
                              </svg>
                            </span> -->
                          </router-link>
                          <!-- THIRD MENU -->
                          <ul
                            v-if="childrenMenu.children.length != 0"
                            class="dropdown-child-child-menu-hover list-unstyled justify-content-center align-items-center bg-white"
                          >
                            <li
                              v-for="childrenChildMenu in childrenMenu.children"
                              :key="childrenChildMenu.id"
                              class="nav-item"
                            >
                              <a
                                v-if="childrenChildMenu.link"
                                class="nav-link childMenu"
                                :href="childrenChildMenu.link"
                                >{{ childrenChildMenu.title }}</a
                              >
                              <router-link
                                v-else
                                class="nav-link childMenu"
                                :to="
                                  findUrl(
                                    childrenChildMenu.linkable_type,
                                    childrenChildMenu.linkable_id,
                                    childrenChildMenu.slug
                                  )
                                "
                                >{{ childrenChildMenu.title }}
                              </router-link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!-- Left links -->
      </div>
      <!-- Collapsible wrapper -->
    </div>
    <!-- Container wrapper -->
  </nav>
</template>

<script>
import findUrl from "@/libraries/links/LinkResolver";
import { BRow, BSkeleton } from "bootstrap-vue";
import DownChev from "./svg/DownChev.vue";
export default {
  name: "Nav",
  components: {
    BRow,
    BSkeleton,
    DownChev,
  },
  computed: {
    menu() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"].menu.header;
      } else {
        return this.$store.getters["front/getHomeData"];
      }
    },
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
  methods: {
    findUrl,
  },
};
</script>

<style scoped>

.dropdown-hover:hover > .dropdown-menu {
  transform: scaleY(1);

}

.dropdown-hover > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}
.dropdown-menu {
  top: 97%;
  border: unset;
  display: inline-block !important;
  transform: scaleY(0);
  transition: transform .3s;
  transform-origin: top;
}

.navbar {
  padding: 0 !important;
  position: inherit;
}
/*.dropdown-hover:hover .dropdown-toggle {
  border-bottom: 2px solid #000;
  border-top: 1px solid #dbdbdb;

}*/
.dropdown-toggle::after {
  display: none;
}
li .nav-item {
  text-align: start;
}

.nav-link {
  position: relative;
  padding-right: unset !important;
  padding-left: unset !important;
  width: fit-content;
  
}
.nav-link::before{
  content: "";
  position: absolute;
  width: 99%;
  height: 1px;
  top: 80%;
  right: 0;
  background-color: #000;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform .3s ;
  
}

.nav-link:hover.nav-link::before{
  transform: scaleX(1);
  transform-origin: right;

}
/* .childMenu {
    color: #666;
  }
  #menuSkelton {
    display: flex;
    justify-content: space-around;
  }
  .mega-menu{
    background: #ffffff;
    min-height: 488px;
    width: 100vw;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    border-top:1px solid #ccc;
  } */
</style>
