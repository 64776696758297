<template>
  <footer id="footer" class="pt-4 pb-3 footer-bg w-100">
    
    <!-- <section class="get-subscribe justify-content-md-between mt-4 container container-2">
      <section class="text-right pl-3 w-100">
        <h6 class="text-color-444 weight-bold fontsize-small">عضویت در خبرنامه</h6>
        <p class="text-color-444 fontsize-mini d-sm-block d-none">
          با اشتراک خبرنامه هفتگی ما از تخفیف ها , اخبارها و مطالب با خبر شوید.
        </p>
      </section>
      <section class="w-100">
        <form id="sendEmailForm" class="w-100 d-flex justify-content-between">
          <span class="my-auto ml-2">
            <svg width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M17.2677 9.06113L13.0023 12.4954C12.1951 13.1283 11.0635 13.1283 10.2563 12.4954L5.95424 9.06113"
                  stroke="#999"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
              />
              <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.88787 3.5H16.3158C17.6752 3.51525 18.969 4.08993 19.896 5.0902C20.823 6.09048 21.3022 7.42903 21.222 8.79412V15.322C21.3022 16.6871 20.823 18.0256 19.896 19.0259C18.969 20.0262 17.6752 20.6009 16.3158 20.6161H6.88787C3.96796 20.6161 2 18.2407 2 15.322V8.79412C2 5.87545 3.96796 3.5 6.88787 3.5Z"
                  stroke="#999"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
              />
            </svg>
          </span>

          <input
              type="text"
              v-model="email"
              class="bg-none w-100 h-100 fontsize-mini"
              placeholder="ایمیل خود را وارد کنید..."
          />
          <button
              @click.prevent="sendEmail()"
              :class="{disabled:loading || disabled}"
              class="
              fontsize-mini
              text-white
              py-1
              px-3
              align-self-end
              newsletter-btn
            "
          >
            عضویت
          </button>
        </form>
      </section>
    </section>
    <div class="main-footer container mb-md-0 mb-4 container-2">
      <div class="footer-call-location pl-3 text-right">
        <section class="mb-4 mt-5">
          <div>
            <svg
                class="d-inline-block"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.3558 2.00793C14.1328 1.97595 13.9087 2.04191 13.7304 2.18381C13.5472 2.32771 13.4326 2.53557 13.4077 2.76841C13.355 3.23908 13.6946 3.66479 14.1646 3.71776C17.4063 4.07951 19.9259 6.60477 20.2904 9.85654C20.3392 10.2922 20.7047 10.621 21.1409 10.621C21.1738 10.621 21.2057 10.619 21.2385 10.615C21.4666 10.59 21.6697 10.4771 21.8132 10.2972C21.9556 10.1174 22.0203 9.89351 21.9944 9.66467C21.5403 5.60746 18.4002 2.45862 14.3558 2.00793ZM14.4181 5.48994C13.942 5.402 13.5048 5.70579 13.4142 6.17047C13.3236 6.63515 13.6283 7.08884 14.0914 7.17978C15.4857 7.45159 16.5623 8.53085 16.8351 9.92989V9.93089C16.9128 10.3336 17.2674 10.6264 17.6757 10.6264C17.7305 10.6264 17.7852 10.6214 17.841 10.6114C18.3041 10.5185 18.6088 10.0658 18.5182 9.60012C18.1109 7.51055 16.5025 5.89666 14.4181 5.48994ZM16.0001 15.0905C16.4515 14.8302 16.9615 14.5362 17.6047 14.673C18.1873 14.7959 20.1731 16.4078 20.7169 16.9665C21.0734 17.3322 21.2716 17.7099 21.3025 18.0877C21.3562 19.5697 19.3425 21.2615 18.976 21.4724C18.489 21.8231 17.9224 22 17.286 22C16.6357 22 15.9106 21.8151 15.1199 21.4464C10.8296 19.6556 4.27553 13.231 2.54266 8.97395C1.82362 7.38903 1.81864 6.08193 2.53071 5.09961C2.81454 4.63892 4.43288 2.71325 5.88192 2.7742C6.26733 2.80718 6.64179 3.00505 7.00928 3.3648C7.56499 3.90942 9.13653 5.90106 9.25803 6.48565C9.39247 7.13521 9.09769 7.65185 8.83576 8.10554C8.7799 8.20307 8.70463 8.31881 8.62217 8.4456C8.3092 8.92683 7.89264 9.56735 8.04004 9.97626C9.09669 12.5705 11.5376 14.8339 14.133 15.8972C14.5343 16.0431 15.1745 15.6235 15.6542 15.3092C15.7791 15.2273 15.8932 15.1525 15.9893 15.0967L16.0001 15.0905Z"
                  fill="#666"
              />
            </svg>
            <h6 class="text-color-999 fontsize-small d-inline-block pr-1">
              شماره تماس و ایمیل:
            </h6>
            <div>
              <template v-if="!loading">
                <span
                    class="fontsize-mini text-color-444 d-md-inline-block d-sm-block"
                >{{ content?.settings?.site?.email }}</span
                ><span class="mx-2 d-md-inline-block d-sm-none">/</span>
                <span
                    class="fontsize-mini text-color-444 d-md-inline-block d-sm-block"
                >{{ content?.settings?.site?.mobile }}</span>
              </template>
              <template v-else>
                <span><b-skeleton width="105px" class="d-inline-block mr-2"/></span>
                <span><b-skeleton width="105px" class="d-inline-block mr-2"/></span>

              </template>

            </div>
          </div>
        </section>
        <section class="mt-lg-0 mt-sm-5 mt-4 mr-lg-0 mr-sm-3">
          <div>
            <svg
                class="d-inline-block"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.5 10.3178C3.5 5.71789 7.34388 2 11.9934 2C16.6561 2 20.5 5.71789 20.5 10.3178C20.5 12.6357 19.657 14.7876 18.2695 16.6116C16.7388 18.6235 14.8522 20.3765 12.7285 21.7524C12.2425 22.0704 11.8039 22.0944 11.2704 21.7524C9.13474 20.3765 7.24809 18.6235 5.7305 16.6116C4.34198 14.7876 3.5 12.6357 3.5 10.3178ZM9.19423 10.5768C9.19423 12.1177 10.4517 13.3297 11.9934 13.3297C13.5362 13.3297 14.8058 12.1177 14.8058 10.5768C14.8058 9.0478 13.5362 7.77683 11.9934 7.77683C10.4517 7.77683 9.19423 9.0478 9.19423 10.5768Z"
                  fill="#666"
              />
            </svg>

            <h6 class="text-color-999 fontsize-small d-inline-block pr-1 ">
              آدرس فروشگاه:
            </h6>
            <div>
              <span v-if="!loading" class="fontsize-mini text-color-444 text-justify">{{
                  content?.settings?.site?.address
                }}</span>
              <b-skeleton v-else width="270px" />
            </div>
          </div>
        </section>
        <div class="enamad-container">
          <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=310257&amp;Code=FWnZM9giWo55ZSduxEpi">
            <img width="90" referrerpolicy="origin" src="https://Trustseal.eNamad.ir/logo.aspx?id=310257&amp;Code=FWnZM9giWo55ZSduxEpi" alt="" style="cursor:pointer" id="FWnZM9giWo55ZSduxEpi">
          </a>
        </div>
      </div>

      <div>
        <section
            class="d-flex justify-content-between align-items-baseline mt-4"
        >
          <div class="footer-logo d-sm-block d-none">
            <img src="@/assets/images/logo.svg" alt=""/>
          </div>
          
          <section class="sotial-networks">
            <a v-if="!loading" :href="!loading ? content?.settings?.social?.facebook : ''" class="mx-2">
              <svg
                  width="17"
                  height="17"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512"
                  xml:space="preserve"
              >
                <path
                    d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
                                C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
                                c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
                                c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
                                c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
                                c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
                                c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
                                c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
                                c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z"
                    fill="#bbb"
                />
              </svg>
            </a>
            <a v-if="!loading" :href="!loading ? content?.settings?.social?.instagram : ''" class="mx-2">
              <svg
                  width="17"
                  height="17"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512"
                  xml:space="preserve"
              >
                <path
                    d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
                                C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
                                h192c61.76,0,112,50.24,112,112V352z"
                    fill="#bbb"
                />
                <path
                    d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
                                c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"
                    fill="#bbb"
                />
                <circle cx="393.6" cy="118.4" r="17.056" fill="#bbb"/>
              </svg>
            </a>
            <a v-if="!loading" :href="!loading ? content?.settings?.social?.twitter : ''" class="mx-2">
              <svg
                  width="17"
                  height="17"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512"
                  xml:space="preserve"
              >
                <path
                    d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
                                c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
                                c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
                                c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
                                c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
                                c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
                                C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
                                C480.224,136.96,497.728,118.496,512,97.248z"
                    fill="#bbb"
                />
              </svg>
            </a>
            <a v-if="!loading" :href="!loading ? content?.settings?.social?.telegram : ''" class="mx-2">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  fill="currentColor"
                  class="bi bi-telegram"
                  viewBox="0 0 16 16"
              >
                <path
                    fill="#bbb"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"
                />
              </svg>
            </a>
            <a v-if="!loading" :href="!loading ? content?.settings?.social?.facebook : ''" class="mx-2">
              <svg
                  width="15"
                  height="15"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512"
                  xml:space="preserve"
              >
                <path
                    d="M448,0H64C28.704,0,0,28.704,0,64v384c0,35.296,28.704,64,64,64h192V336h-64v-80h64v-64c0-53.024,42.976-96,96-96h64v80
                                h-32c-17.664,0-32-1.664-32,16v64h80l-32,80h-48v176h96c35.296,0,64-28.704,64-64V64C512,28.704,483.296,0,448,0z"
                    fill="#bbb"
                />
              </svg>
            </a>
          </section>
        </section>
        <section
            class="
            quick-access
            d-lg-flex d-none
            justify-content-between
            align-items-baseline
            my-4
          "
        >
          <h6 class="text-color-444 fontsize-small font-weight-bold">
            دسترسی سریع:
          </h6>
          <nav>
            <ul class="nav fontsize13">
              <template v-if="!loading">
                <li
                    v-for="item in content.menu.footer"
                    :key="item.id"
                    class="nav-item fontsize-mini mr-3"
                >
                  <DynamicLinker :item="item">{{ item.title }}</DynamicLinker>
                </li>
              </template>
              <template v-else>
                <li
                    v-for="index in 5"
                    :key="index"
                    class="nav-item"
                >
                  <a class="nav-link">
                    <b-skeleton width="40px" />
                  </a>
                </li>
              </template>

            </ul>
          </nav>
        </section>
        <hr/>
        <section class="d-flex text-sm-right text-center mt-4">
          <svg
              class="d-sm-inline-block d-none"
              height="20"
              viewBox="0 0 512 512"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                fill="#666"
                d="m178.925781 177.925781c-42.5 42.5-42.5 111.648438 0 154.148438s111.648438 42.5 154.148438 0c7.8125-7.8125 20.476562-7.8125 28.285156 0s7.808594 20.476562 0 28.285156c-29.046875 29.046875-67.203125 43.570313-105.359375 43.570313s-76.3125-14.523438-105.359375-43.570313c-58.09375-58.097656-58.09375-152.625 0-210.71875 58.097656-58.09375 152.625-58.09375 210.71875 0 7.8125 7.808594 7.8125 20.472656 0 28.285156-7.808594 7.808594-20.472656 7.808594-28.285156 0-42.5-42.5-111.648438-42.5-154.148438 0zm258.09375-102.945312c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469 46.8125 0 92.617188-12.757812 132.460938-36.894531 9.449218-5.722657 12.46875-18.019531 6.746093-27.46875-5.726562-9.449219-18.023437-12.46875-27.46875-6.742188-33.59375 20.347657-72.234375 31.105469-111.738281 31.105469-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216c0 42.589844-12.664062 84.042969-36.625 119.882812-6.140625 9.183594-3.671875 21.605469 5.507812 27.742188 9.1875 6.140625 21.605469 3.671875 27.742188-5.507812 28.378906-42.441407 43.375-91.585938 43.375-142.117188 0-68.378906-26.628906-132.667969-74.980469-181.019531zm0 0"
            />
          </svg>
          <p class="fontsize13 text-color-999 mr-2">
            تمامی حقوق این سایت مربوط به سایت  نبی زاده می باشد
            . طراحی شده: <a style="color: var(--color-theme)" target="_blank" href="https://shetabit.com/">شتاب</a>
          </p>
        </section>
      </div>
    </div> -->
    <section class=" w-90 mr-auto ml-auto" style="width: 84%;">
      <div class="d-flex justify-content-between align-items-center mt-2">
        <Logo class="logo-footer"/>
        <a href="#" class="move-to-top d-flex align-items-center fontsize-md-16 font-weight-lighter text-color-666 border border-dark border-radius10">
          <span class="d-flex pt-1">
            بازگشت به بالا
          </span>
          <span class="arrow-top d-flex">
            
<!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<svg width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#000000"><polyline points="16 40 32 24 48 40"/></svg>

          </span>
        </a>
      </div>
      <div class="address-footer d-flex align-items-center flex-wrap mt-5" style="gap: 8px;">
        <div class="d-flex align-items-center" style="gap: 8px;">

          <h6 class="text-color-999 fontsize-small d-flex m-0 p-0" v-if="!loading">
            شماره تماس :
          </h6>
          <template v-if="!loading">
           
            <!-- <span
                class="fontsize-md-15 font-weight-bold text-color-444 d-md-inline-block d-sm-block"
            >{{ content?.settings?.site?.mobile }}</span> -->
            <span
                class="fontsize-md-15 font-weight-bold text-color-444 d-md-inline-block d-sm-block"
            >{{ content.settings.site.support_number??content.settings.site.mobile }}</span>
            <span class=" d-md-inline-block d-sm-none line-address-footer">|</span>

          </template>
        </div>
        <div class="d-flex align-items-center" style="gap: 8px;">
          <h6 class="text-color-999 fontsize-small d-flex m-0 p-0" v-if="!loading">
            آدرس ایمیل:
           </h6>
           <template v-if="!loading">
             <span
                 class="fontsize-md-15 font-weight-bold text-color-444 d-md-inline-block d-sm-block"
             >{{ content?.settings?.site?.email }}</span
             ><span class="d-md-inline-block d-sm-none line-address-footer">|</span>
           
           </template>
        </div>
        <div class="d-flex align-items-center" style="gap: 8px;">
         

          <h6 class="text-color-999 fontsize-small d-flex m-0 p-0">
            آدرس فروشگاه:
          </h6>
          <template v-if="!loading">
            <span  class="address-filde fontsize-md-15 font-weight-bold text-color-444 d-md-inline-block d-sm-block">{{
                content?.settings?.site?.address
              }}</span>

            <!-- <b-skeleton v-else width="270px" /> -->
          </template>
        </div>
        <div class="d-flex align-items-center">
          <span class="d-md-inline-block d-sm-none line-address-footer">|</span>

          <h6 class="text-color-999 fontsize-small d-flex m-0 p-0 mr-lg-2" v-if="!loading">
            هفت روز هفته، از ساعت 8 الی 23 پاسخگوی سوالات شما هستیم.
          </h6>
        </div>
        <div class="d-flex align-items-center flex-wrap">
          
         
          <template v-if="loading">
            <span><b-skeleton width="105px" class="d-inline-block mr-2"/></span>
            <span><b-skeleton width="105px" class="d-inline-block mr-2"/></span>
            <span><b-skeleton width="105px" class="d-inline-block mr-2"/></span>
            <span><b-skeleton width="105px" class="d-inline-block mr-2"/></span>
            <span><b-skeleton width="105px" class="d-inline-block mr-2"/></span>

          </template>

        </div>
      </div>
      <div class="list-menu-footer mt-5">
        <div v-if="!loading" >
          <h6 :class="{'d-none':flagResize}" class="text-color-444 fontsize-small font-weight-bold">
            راهنمای خرید
          </h6>
          <nav v-if="!flagResize">
            <ul class="nav fontsize13" style="gap: 8px;">
              <template v-if="!loading">
                <li
                    v-for="item in content.menu.footer.slice(0,4)"
                    :key="item.id"
                    class="nav-item fontsize-mini"
                >
                  <DynamicLinker :item="item" >{{ item.title }}</DynamicLinker>
                </li>
              </template>
              <!-- <template v-else>
                <li
                    v-for="index in 5"
                    :key="index"
                    class="nav-item"
                >
                  <a class="nav-link">
                    <b-skeleton width="40px" />
                  </a>
                </li>
              </template> -->

            </ul>
          </nav>
          <details  v-else class="select-menu-footer w-100 bg-transparent border border-dark border-radius15">
            <summary class="summary d-flex justify-content-between align-items-center cursor-pointer">
             <span class="text-color-444 fontsize-md-16 font-weight-bold">
              راهنمای خرید
             </span>

              <span>
                <svg class="d-flex" id="icon-summery" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z" fill="currentColor"></path>
              </svg>
                </span>
            </summary>
            <div class="d-flex flex-column pt-2" style="gap: 8px; "> 
              <DynamicLinker class="fontsize-md-14" v-for="item in content.menu.footer.slice(0,4)"
            :key="item.id" :item="item" >{{ item.title }}</DynamicLinker>
            </div>

          </details>
        </div>
        <div v-if="!loading" >
          <h6 :class="{'d-none':flagResize}" class="text-color-444 fontsize-small font-weight-bold">
            دسترسی سریع
          </h6>
          <nav v-if="!flagResize">
            <ul class="nav fontsize13" style="gap: 8px;">
              <template v-if="!loading">
                <li
                    v-for="item in content.menu.footer.slice(4,8)"
                    :key="item.id"
                    class="nav-item fontsize-mini"
                >
                  <DynamicLinker  :item="item">{{ item.title }}</DynamicLinker>
                </li>
              </template>
              <!-- <template v-else>
                <li
                    v-for="index in 5"
                    :key="index"
                    class="nav-item"
                >
                  <a class="nav-link">
                    <b-skeleton width="40px" />
                  </a>
                </li>
              </template> -->

            </ul>
          </nav>
          <details v-else class="select-menu-footer w-100 bg-transparent border border-dark border-radius15">
            <summary class="summary d-flex justify-content-between align-items-center cursor-pointer">
              <span class="text-color-444 fontsize-small font-weight-bold">
                دسترسی سریع
              
              </span>
  
                <span>
                  <svg class="d-flex" id="icon-summery" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z" fill="currentColor"></path>
                </svg>
                  </span>
            </summary>
            <div class="d-flex flex-column pt-2" style="gap: 8px;"> 
              <DynamicLinker class="fontsize-md-14" v-for="item in content.menu.footer.slice(4,8)"
            :key="item.id" :item="item" >{{ item.title }}</DynamicLinker>
            </div>

          </details>
        </div>
        <div v-if="!loading" >
          <h6 :class="{'d-none':flagResize}" class="text-color-444 fontsize-small font-weight-bold">
            دسته بندی
          </h6>
          <nav v-if="!flagResize">
            <ul class="nav fontsize13" style="gap: 8px;">
              <template v-if="!loading">
                <li
                    v-for="item in content.menu.footer.slice(8,content.menu.footer.length)"
                    :key="item.id"
                    class="nav-item fontsize-mini"
                >
                  <DynamicLinker :item="item">{{ item.title }}</DynamicLinker>
                </li>
              </template>
              <!-- <template v-else>
                <li
                    v-for="index in 5"
                    :key="index"
                    class="nav-item"
                >
                  <a class="nav-link">
                    <b-skeleton width="40px" />
                  </a>
                </li>
              </template> -->

            </ul>
          </nav>
          <details v-else class="select-menu-footer w-100 bg-transparent border border-dark border-radius15">
            <summary class="summary d-flex justify-content-between align-items-center cursor-pointer" >
              <span class="text-color-444 fontsize-small font-weight-bold">
                دسته بندی
               </span>
  
                <span>
                  <svg class="d-flex" id="icon-summery" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z" fill="currentColor"></path>
                </svg>
                  </span>
            </summary>
            <div class="d-flex flex-column pt-2" style="gap: 8px;"> 
              <DynamicLinker class="fontsize-md-14" v-for="item in content.menu.footer.slice(8,content.menu.footer.length)"
            :key="item.id" :item="item" >{{ item.title }}</DynamicLinker>
            </div>

          </details>
        </div>
        <div  class="d-flex flex-column justify-content-between ">
          <section class="sotial-networks d-flex flex-column justify-content-between h-100">
          <div>
            <h6 class="text-color-444 fontsize-small font-weight-bold">
              با ما همراه باشید
            </h6>
            <div class="mt-3" v-if="!loading"> 
              <a v-if="!loading" :href="!loading ? content?.settings?.social?.facebook : ''" class="mx-2 d-inline-block social-icon-footer">
                <svg
                    width="17"
                    height="17"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                >
                  <path
                      d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
                                  C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
                                  c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
                                  c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
                                  c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
                                  c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
                                  c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
                                  c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
                                  c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z"
                      fill="#bbb"
                  />
                </svg>
              </a>
              <a v-if="!loading" :href="!loading ? content?.settings?.social?.instagram : ''" class="mx-2 d-inline-block social-icon-footer">
                <svg
                    width="17"
                    height="17"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                >
                  <path
                      d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
                                  C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
                                  h192c61.76,0,112,50.24,112,112V352z"
                      fill="#bbb"
                  />
                  <path
                      d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
                                  c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"
                      fill="#bbb"
                  />
                  <circle cx="393.6" cy="118.4" r="17.056" fill="#bbb"/>
                </svg>
              </a>
              <a v-if="!loading" :href="!loading ? content?.settings?.social?.twitter : ''" class="mx-2 d-inline-block social-icon-footer">
                <svg
                    width="17"
                    height="17"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                >
                  <path
                      d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
                                  c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
                                  c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
                                  c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
                                  c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
                                  c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
                                  C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
                                  C480.224,136.96,497.728,118.496,512,97.248z"
                      fill="#bbb"
                  />
                </svg>
              </a>
              <a v-if="!loading" :href="!loading ? content?.settings?.social?.telegram : ''" class="mx-2 d-inline-block social-icon-footer">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    fill="currentColor"
                    class="bi bi-telegram"
                    viewBox="0 0 16 16"
                >
                  <path
                      fill="#bbb"
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"
                  />
                </svg>
              </a>
              <a v-if="!loading" :href="!loading ? content?.settings?.social?.facebook : ''" class="mx-2 d-inline-block social-icon-footer">
                <svg
                    width="15"
                    height="15"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                >
                  <path
                      d="M448,0H64C28.704,0,0,28.704,0,64v384c0,35.296,28.704,64,64,64h192V336h-64v-80h64v-64c0-53.024,42.976-96,96-96h64v80
                                  h-32c-17.664,0-32-1.664-32,16v64h80l-32,80h-48v176h96c35.296,0,64-28.704,64-64V64C512,28.704,483.296,0,448,0z"
                      fill="#bbb"
                  />
                </svg>
              </a>
            </div>
            <template v-if="loading">
              <span><b-skeleton width="26px"  height="26px" style="border-radius: 50%;" class="d-inline-block mr-2"/></span>
              <span><b-skeleton width="26px"  height="26px" style="border-radius: 50%;" class="d-inline-block mr-2"/></span>
              <span><b-skeleton width="26px"  height="26px" style="border-radius: 50%;" class="d-inline-block mr-2"/></span>
              <span><b-skeleton width="26px"  height="26px" style="border-radius: 50%;" class="d-inline-block mr-2"/></span>
              <span><b-skeleton width="26px"  height="26px" style="border-radius: 50%;" class="d-inline-block mr-2"/></span>
  
            </template>
          </div>
          <div class="mt-5">
            <h6 class="text-color-444 fontsize-small font-weight-bold">
              از جدیدترین تخفیف‌ها باخبر شوید

            </h6>
            <div class="mt-3">
              <form id="sendEmailForm" class="w-100 d-flex justify-content-between align-items-center border border-dark pr-1">
                <span class="my-auto ml-2">
                  <svg class="email-icone" width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M17.2677 9.06113L13.0023 12.4954C12.1951 13.1283 11.0635 13.1283 10.2563 12.4954L5.95424 9.06113"
                        stroke="#999"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.88787 3.5H16.3158C17.6752 3.51525 18.969 4.08993 19.896 5.0902C20.823 6.09048 21.3022 7.42903 21.222 8.79412V15.322C21.3022 16.6871 20.823 18.0256 19.896 19.0259C18.969 20.0262 17.6752 20.6009 16.3158 20.6161H6.88787C3.96796 20.6161 2 18.2407 2 15.322V8.79412C2 5.87545 3.96796 3.5 6.88787 3.5Z"
                        stroke="#999"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                  </svg>
                </span>
      
                <input
                 
                    type="text"
                    v-model="email"
                    class="bg-none w-100 h-100 fontsize-mini email-box"
                    placeholder="ایمیل خود را وارد کنید..."
                />
                <button
                    @click.prevent="sendEmail()"
                    :class="{disabled:loading || disabled}"
                    class="
                    fontsize-mini
                    text-white
                    py-1
                    px-3
                    align-self-end
                    newsletter-btn
                  "
                >
                  عضویت
                </button>
              </form>
            </div>
          </div>
          </section>
        </div>
      </div>
      <div class="box-info-enamad mt-5 mb-5">
        <div class="info-shop-footer">
          <h6 class="title-info-shop-footer text-color-444  fontsize-sm-18 font-weight-bold">
            فروشگاه اینترنتی  نبی زاده، بررسی، انتخاب و خرید آنلاین
          </h6>
          <p class="content-info-shop-footer fontsize-sm-13 mt-3 text-justify">
             نبی زاده به عنوان یکی از قدیمی‌ترین فروشگاه های اینترنتی با بیش از یک دهه تجربه، با پایبندی به سه اصل، پرداخت در محل، ۷ روز ضمانت بازگشت کالا و تضمین اصل‌بودن کالا موفق شده تا همگام با فروشگاه‌های معتبر جهان، به بزرگ‌ترین فروشگاه اینترنتی ایران تبدیل شود. به محض ورود به سایت های اینترنتی با دنیایی از کالا رو به رو می‌شوید! هر آنچه که نیاز دارید و به ذهن شما خطور می‌کند در اینجا پیدا خواهید کرد.
          </p>
        </div>
        <div class="enamde d-flex flex-row-reverse">
          <template v-if="!loading">
           

           
            <!-- <a referrerpolicy='origin' target='_blank' href='https://trustseal.enamad.ir/u?id=354758&code=wFyJebOqhP55BEISqaeN'><img referrerpolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=354758&code=wFyJebOqhP55BEISqaeN' alt='' style='cursor:pointer' code='wFyJebOqhP55BEISqaeN'></a> -->
         
            <a target="_blank" href="https://trustseal.enamad.ir/?id=354758&Code=wFyJebOqhP55BEISqaeN"><img referrerpolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=354758&Code=wFyJebOqhP55BEISqaeN' alt='' style='cursor:pointer' Code='wFyJebOqhP55BEISqaeN'></a>
          </template>
          <template v-else>
            <span><b-skeleton width="105px" height="105px" class="d-inline-block mr-2"/></span>
            <!-- <span><b-skeleton width="105px" height="105px" class="d-inline-block mr-2"/></span> -->
            <!-- <span><b-skeleton width="105px" height="105px" class="d-inline-block mr-2"/></span> -->

          </template>
        </div>
      </div>
      <div class="webSiteCopyRight d-flex flex-wrap align-items-center justify-content-between border-top border-grey-900 pt-3">
        <section class="d-flex align-items-center text-sm-right text-center" style="gap: 5px;">
          <svg
              class="d-sm-inline-block d-none"
              height="20"
              viewBox="0 0 512 512"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                fill="#666"
                d="m178.925781 177.925781c-42.5 42.5-42.5 111.648438 0 154.148438s111.648438 42.5 154.148438 0c7.8125-7.8125 20.476562-7.8125 28.285156 0s7.808594 20.476562 0 28.285156c-29.046875 29.046875-67.203125 43.570313-105.359375 43.570313s-76.3125-14.523438-105.359375-43.570313c-58.09375-58.097656-58.09375-152.625 0-210.71875 58.097656-58.09375 152.625-58.09375 210.71875 0 7.8125 7.808594 7.8125 20.472656 0 28.285156-7.808594 7.808594-20.472656 7.808594-28.285156 0-42.5-42.5-111.648438-42.5-154.148438 0zm258.09375-102.945312c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469 46.8125 0 92.617188-12.757812 132.460938-36.894531 9.449218-5.722657 12.46875-18.019531 6.746093-27.46875-5.726562-9.449219-18.023437-12.46875-27.46875-6.742188-33.59375 20.347657-72.234375 31.105469-111.738281 31.105469-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216c0 42.589844-12.664062 84.042969-36.625 119.882812-6.140625 9.183594-3.671875 21.605469 5.507812 27.742188 9.1875 6.140625 21.605469 3.671875 27.742188-5.507812 28.378906-42.441407 43.375-91.585938 43.375-142.117188 0-68.378906-26.628906-132.667969-74.980469-181.019531zm0 0"
            />
          </svg>
          <p class="fontsize13 text-color-999 m-0">
            تمامی حقوق این سایت مربوط به سایت  نبی زاده می باشد
          
          </p>
        </section>
        <div class="Copyright">
          <p class="m-0">
             طراحی شده توسط گروه <a class="font-weight-bold" style="color: var(--color-theme)" target="_blank" href="https://shetabit.com/">شتاب</a>
          </p>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import findUrl from "@/libraries/links/LinkResolver";
import DynamicLinker from "./DynamicLinker";
import DynamicOptions from "./DynamicOptions.vue";
import {BSkeleton} from 'bootstrap-vue'
import Logo from "@/parts/Front/components/AtlasLogo";

 
export default {
  name: "Footer",
  components: {DynamicLinker, BSkeleton,Logo,DynamicOptions},
  computed: {
    // دریافت محتوای درخواست هوم
    content() {
      console.log(this.$store.getters["front/getHomeData"]);
      return this.$store.getters["front/getHomeData"];
    },
    loading() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
  data() {
    return {
      email: null, disabled: false,flagResize:false,flagSelect:false
    };
  },
  methods: {
    findUrl,
    sendEmail() {
      if (!this.email) {
        return window.swal({
          title: "خطا!",
          text: "لطفا ایمیل خود را وارد کنید",
          icon: "warning",
          timer: 2000,
        });
      } else if (!this.validEmail(this.email)) {

        return window.swal({
          title: "خطا!",
          text: "لطفا فرمت ایمیل خود را به درستی وارد کنید",
          icon: "warning",
          timer: 2000,
        });
      }
      const fromData = new FormData();
      fromData.append('email', this.email);
      this.disabled = true;
      this.$axios.post("/front/newsletters", fromData).then(response => {
        this.disabled = false;
        this.email = null;
        window.swal({
          title: "تبریک",
          text: "ایمیل شما برای عضویت در خبرنامه ما ثبت سیستم شد",
          icon: "success",
          timer: 2000,
        });
      })

    },
    validEmail: function (email) {
      var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    changeResize(){
      console.log(innerWidth);
      if(innerWidth<1075){
        this.flagResize=true;
        this.flagSelect=true;
      }else{
        this.flagResize=false;
        this.flagSelect=false;

      }
    }
    ,
    changPage(item){
      console.log(item);
      console.log(item.target.value);
        // alert(1235)
        // this.$router.push(this.findUrl(item.linkable_type, item.linkable_id, item.slug))
      }
  },
  mounted(){
    window.addEventListener("resize",this.changeResize)
  },
  beforeDestroy(){
    window.removeEventListener("resize",this.changeResize)

  }
};
</script>

<style scoped>

/*#footer{
  width: 100%;

}*/
.move-to-top{
  
  padding: 5px 16px;
  gap: 5px;
  /*border: 1px solid #000; 
  
    border-radius: 10px;
    scroll-behavior: smooth;*/
}
/*.social-icon-footer:hover{
  animation: rotateIcon .5s forwards;
}
@keyframes rotateIcon {
  0%{
    transform: rotateY(0);
  }
  100%{
    transform: rotateY(90deg);

  }
}*/
.arrow-top {
  transform: translateY(-2px);
}
.arrow-top svg{
  fill: #414141;
  width: 17px;
  height: 17px;
}
.list-menu-footer,.box-info-enamad{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 1rem;

}
.info-shop-footer{
  grid-column: 1/4;
}
.content-info-shop-footer{
  grid-column: 3/5;
  /*text-align: justify;*/
  font-size:13px;

}
.sotial-networks svg{
  width: 26px;
  height: 26px;
}
.email-box{
  padding: 6px;
}
.nav{
  flex-direction: column;
}
.enamde img{
  width: 100px;
  height: 100px;
}
.imgSkeleton {
  height: 70px;
}

.errorEmail {
  border-bottom: 1px solid red;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.newsletter-btn {
  background: var(--color-theme);
  padding: 7px 1rem !important;
}
.email-icone{
  width: 19px !important;
  height: 19px !important;
}
.select-menu-footer {
  padding: 8px 16px;
  /* width: 100%;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 15px;*/
}
.summary::marker{
  content: "";

}
details[open] #icon-summery{
  transform: rotate(-90deg);
}
@media (min-width: 1024px) {
  .footer-call-location {
    position: relative;
  }
  .enamad-container {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}
}
@media (max-width: 1074px){

  .address-footer{
flex-direction: column;
    gap: 8px;
  margin-top: 3rem !important;

}
.address-footer div{
  align-items: start !important;
  width: 100%;

}
.line-address-footer{
  display: none !important;
}
.logo-footer{
  margin: unset !important;
}
.list-menu-footer{
  grid-template-columns: repeat(1,1fr);
}
.list-menu-footer div{
  width: 100%;
}
section.sotial-networks {
  gap: 3rem;
  text-align: center;
  margin-top: 3rem;
}
.box-info-enamad {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  margin-top: 3rem;
}
.box-info-enamad div{
  width: 100%;
  
}
.enamde{
  justify-content: center;
}
.Copyright{
  font-size: 13px;
  color: var(--color-999);
}

}
@media (max-width: 768px){
  footer#footer {
    margin-bottom: 4rem;
  }
  .move-to-top{
    font-size: 14px;
    padding: 2px 16px !important;
  }
  .arrow-top {
    transform: translateY(0px) !important;
}
}
@media (max-width: 500px){
  .address-filde {
    font-size: 13px !important;
  }
  .webSiteCopyRight{
    justify-content: center !important;
  }
 
}
</style>
