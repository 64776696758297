<template>
  <section 
    v-if="hasAnyBanners"
    class="banners d-flex justify-content-between container banner my-5"
  >
    <router-link
      v-for="(banner, index) in banners"
      :key="index"
      :to="
        banner
          ? banner.linkable_type != null
            ? findUrl(banner.linkable_type, banner.linkable_id)
            : banner.link
          : ''
      "
      :target="banner && banner.new_tab == 1 ? '_blank' : ''"
      class="overflow-hidden cardBaner"
      :class="'cardBaner'+(index+1)"
    >
    <figure :class="'glow'+(index+1)" class="glow">
      <img 
        loading="lazy"
        v-if="banner"
        :src="banner.picture"
        class="w-100"
        alt=""
      />
    </figure>
    </router-link>
  </section>
</template>

<script>
import findUrl from "@/libraries/links/LinkResolver";
export default {
  name: "Banners",
  props: {
    banners: Array,
  },
  methods: {
    findUrl,
    // func(classNameParent,calssNAmeChild)
//     func(){
//       const $card = document.querySelector('.cardBaner');
//       console.log($card);
// let bounds;

// function rotateToMouse(e) {
//   const mouseX = e.clientX;
//   const mouseY = e.clientY;
//   const leftX = mouseX - bounds.x;
//   const topY = mouseY - bounds.y;
//   const center = {
//     x: leftX - bounds.width / 2,
//     y: topY - bounds.height / 2
//   }
//   const distance = Math.sqrt(center.x**4 + center.y**4);
  
//   $card.style.transform = `
//     scale3d(1.07, 1.07, 1.07)
//     rotate3d(
//       ${center.y / 100},
//       ${-center.x / 100},
//       0,
//       ${Math.log(distance)* 2}deg
//     )
//   `;
  
//   $card.querySelector('.glow').style.backgroundImage = `
//     radial-gradient(
//       circle at
//       ${center.x * 1 + bounds.width/2}px
//       ${center.y * 1 + bounds.height/2}px,
//       #ffffff55,
//       #0000000f
//     )
//   `;
// }

// $card.addEventListener('mouseenter', () => {
//   bounds = $card.getBoundingClientRect();
//   document.addEventListener('mousemove', rotateToMouse);
// });

// $card.addEventListener('mouseleave', () => {
//   document.removeEventListener('mousemove', rotateToMouse);
//   $card.style.transform = '';
//   $card.style.background = '';
// });
//     }
  },
  computed: {
    hasAnyBanners() {
      let ok = false;
      this.banners.every((banner) => {
        if (banner) {
          ok = true;
          return false;
        }
        return true;
      });
      return ok;
    },
  },
  // mounted(){
  //   if(this.hasAnyBanners){

  //     this.func()
  //   }
  // },
  // watch:{
  //   hasAnyBanners(newVal){
  //     if(newVal){
  //       // moveBaner(".cardBaner",".glow")
  //       this.func()
  //     }
  //   }
  // }
};

</script>

<style scoped>
/*.banners  {
  height: 300px;
}
.cardBaner {
 
  height: 100%;

  box-shadow: 0 1px 5px #00000099;
  
  border-radius: 10px;

  
  position: relative;
  
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0);
}

.cardBaner:hover {
  transition-duration: 150ms;
  box-shadow: 0 5px 20px 5px #00000044;
}

.cardBaner .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  
}
.glow img{
  height: 100%;
}*/
</style>
