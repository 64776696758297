import { render, staticRenderFns } from "./DynamicLinker.vue?vue&type=template&id=ddfa12e0&scoped=true&"
import script from "./DynamicLinker.vue?vue&type=script&lang=js&"
export * from "./DynamicLinker.vue?vue&type=script&lang=js&"
import style0 from "./DynamicLinker.vue?vue&type=style&index=0&id=ddfa12e0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddfa12e0",
  null
  
)

export default component.exports